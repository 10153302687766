import validate from "/usr/src/nuxt-app/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "auth-token": () => import("/usr/src/nuxt-app/middleware/auth-token.ts"),
  authenticated: () => import("/usr/src/nuxt-app/middleware/authenticated.ts"),
  authentication: () => import("/usr/src/nuxt-app/middleware/authentication.ts")
}