import { default as _91_46_46_46slug_935NsE7hKSv3Meta } from "/usr/src/nuxt-app/pages/fromToken/[...slug].vue?macro=true";
import { default as generatelYOp06cOFMMeta } from "/usr/src/nuxt-app/pages/generate.vue?macro=true";
import { default as indexAoBMx6PnC1Meta } from "/usr/src/nuxt-app/pages/index.vue?macro=true";
import { default as loginlzkgvgJgWgMeta } from "/usr/src/nuxt-app/pages/login.vue?macro=true";
import { default as _91_46_46_46slug_934OxV1m5P7yMeta } from "/usr/src/nuxt-app/pages/register/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93qoqjORXuK1Meta } from "/usr/src/nuxt-app/pages/reset-password/[...slug].vue?macro=true";
import { default as resetx71D5jr26DMeta } from "/usr/src/nuxt-app/pages/reset.vue?macro=true";
export default [
  {
    name: "fromToken-slug",
    path: "/fromToken/:slug(.*)*",
    meta: _91_46_46_46slug_935NsE7hKSv3Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/fromToken/[...slug].vue")
  },
  {
    name: "generate",
    path: "/generate",
    component: () => import("/usr/src/nuxt-app/pages/generate.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexAoBMx6PnC1Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginlzkgvgJgWgMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/login.vue")
  },
  {
    name: "register-slug",
    path: "/register/:slug(.*)*",
    component: () => import("/usr/src/nuxt-app/pages/register/[...slug].vue")
  },
  {
    name: "reset-password-slug",
    path: "/reset-password/:slug(.*)*",
    component: () => import("/usr/src/nuxt-app/pages/reset-password/[...slug].vue")
  },
  {
    name: "reset",
    path: "/reset",
    component: () => import("/usr/src/nuxt-app/pages/reset.vue")
  }
]